// Here you can add other styles
.core-logo-sidebar {
  width: 85%;
}
.core-logo-header {
  width: 60%;
  margin: 0px;
}

.react-viewer-mask {
  z-index: 9990 !important;
}

.react-viewer-close {
  z-index: 9999 !important;
}

.react-viewer-canvas {
  z-index: 9995 !important;
}

.react-viewer-footer {
  z-index: 9999 !important;
}

/* Card Custom Style */
.card-title {
  font-size: 24px;
  color: #00a14b;

  &.child-header {
    font-weight: 500;
    color: #2c2d2c;
    font-size: 18px !important;
  }
}
.page-item.active .page-link {
  background-color: #00a14b;
  border-color: #00a14b;
}
.page-link {
  color: black;
}

.card-footer {
  @include custom-border-radius(0px 0px 24px 24px !important);
}

.card {
  @include custom-border-radius(24px 24px 24px 24px);
  @include custom-box-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.MuiIconButton-root {
  &:focus {
    outline: none;
  }
}

/* Sidebar Custom Style */
.c-sidebar {
  background: #006d36;

  .c-sidebar-brand {
    background: none;

    .core-logo-sidebar {
      .c-avatar-img {
        @include custom-border-radius(0px);
      }
    }
  }

  &.admin-sidebar,
  &.distributor-sidebar {
    background: #2c2d2c;
  }

  .c-sidebar-nav {
    margin-top: 38px;

    .c-sidebar-nav-dropdown {
      .c-sidebar-nav-dropdown-toggle {
        color: #ffffff;
        opacity: 0.5;

        &:hover {
          opacity: 1;
          background: #00a14b;
        }

        &.c-active {
          opacity: 1;
        }
      }
    }

    .c-sidebar-nav-item {
      &:hover {
        .c-sidebar-nav-link {
          background: transparent;
        }
      }

      &.auto-highlight {
        .c-sidebar-nav-link {
          opacity: 1;
        }
      }

      &.menu-top-divider {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #ffffff66;
      }

      &.menu-bottom-divider {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #ffffff66;
      }

      .c-sidebar-nav-link {
        opacity: 0.5;
        color: #ffffff;
        font-size: 16px;

        @media (hover: hover), (-ms-high-contrast: none) {
          &:hover {
            opacity: 1;
            background: #00a14b;
          }
        }

        &:hover {
          opacity: 1;
          background: #00a14b;
          border-left: 3px solid #ffffff;
        }

        &.c-active {
          opacity: 1;
          border-left: 3px solid #ffffff;
        }

        .c-sidebar-nav-icon,
        .MuiSvgIcon-root {
          color: #ffffff;
        }
      }
    }
  }
}

/* site header */
.c-header {
  border-bottom: 0px;
  background: transparent;

  .c-header-nav-btn {
    color: #2c2d2c;
    font-size: 16px;
  }

  /* Header Nav Style */
  .c-header-nav {
    .c-header-nav-item,
    .c-header-nav-items {
      .dropdown-menu {
        .dropdown-item {
          &.active,
          &:active {
            opacity: 1;
            background-color: #00a14b;
          }
        }
      }

      .c-header-nav-link {
        color: #2c2d2c;
        font-size: 16px;
        font-weight: bold;

        &.active {
          color: #00a14b;
        }

        .c-avatar-img {
          @include custom-border-radius(0px !important);
        }
      }
    }
  }
}

/* Header Panel Not Sticky */
.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  position: relative !important;
}

.container-fluid {
  @include custom-alert-box();
}

.payment-stats,
.payment-stats-comparison {
  /* Date Time Input Element */
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  select.form-control,
  .rmsc .dropdown-container {
    background: #f6f6f6 !important;
    border-color: transparent !important;
  }

  .rmsc .multi-select {
    margin-top: 8px !important;
  }

  /* Customized MultiSelect Element Style */
  .rmsc {
    --rmsc-radius: 0.25rem !important; /* Radius */
    --rmsc-h: calc(1.5em + 0.75rem + 0px) !important; /* Height */
  }

  /* Switch Style1 */
  .c-switch[class*="-3d"] .c-switch-slider::before {
    background: green !important;
  }

  /* Payment Statistics Time Option Buttons */
  .time-options {
    border: 1px solid #c4c4c4;
    @include custom-box-sizing(border-box);
    @include custom-border-radius(16px);

    button {
      width: 90px;
      mix-blend-mode: multiply;
      border: 0px !important;
      background: #ffffff !important;
      @include custom-border-radius(16px !important);

      &.active {
        mix-blend-mode: darken;
        border: 1px solid #006d36 !important;
        @include custom-box-sizing(border-box !important);
        @include custom-box-shadow(0px 1px 4px rgba(0, 0, 0, 0.25) !important);
      }

      &:hover {
        mix-blend-mode: darken;
        border: 1px solid #006d36 !important;
        @include custom-box-sizing(border-box !important);
        @include custom-box-shadow(0px 1px 4px rgba(0, 0, 0, 0.25) !important);
      }
    }
  }

  .c-chartjs-tooltip {
    width: 252px;
    color: #000000;
    background: #ffffff;
    margin-left: -66px;
    left: 36px;
    position: absolute;
    transform: translate(-26%, 3%);
    border: 0.5px solid #dfdfdf;
    @include custom-border-radius(4px);
    @include custom-box-shadow(0px 1px 4px rgba(0, 0, 0, 0.25) !important);

    .c-tooltip-header {
      padding: 5px 0px;
      color: #979797;
      border-bottom: 0.5px solid #dfdfdf;
    }

    .c-tooltip-body-item {
      padding: 5px 0px;

      .c-tooltip-body-item-color {
        @include custom-border-radius(8px);
      }
      .c-tooltip-body-item-label {
        color: #979797;
      }

      .c-tooltip-body-item-value {
        color: #000000;
      }
    }
  }
}
.support-playlists {
  color: #2c2d2c;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* RS User Login */
.rs-login,
.rs-support,
.rs-profile-settings {
  @include youtube-video-instruction();

  .rs-top-header {
    position: absolute;
    height: 72px;
    left: 0%;
    right: 0%;
    top: 0px;
    background: #006d36;

    .core-logo-header {
      .c-avatar-img {
        @include custom-border-radius(0px);
      }
    }

    a {
      padding: 25px 0px;
      color: #ffffff;
    }
  }

  .card {
    @include custom-border-radius(12px);

    .card-body {
      padding: 1.7rem;
      @include custom-alert-box();

      .card-title {
        font-size: 24px;

        &.errorheader {
          color: #e37575 !important;
        }
      }

      .profile-info {
        div:nth-child(odd) {
          opacity: 0.7;
          color: #2c2d2c;
        }
      }

      .rs-login-title {
        color: #2c2d2c;
        margin-top: 10px;
        text-align: justify;
      }

      .rs-forgot-password {
        text-align: right;
        margin-bottom: 25px;

        a {
          color: #2c2d2c;
          text-decoration: underline;
        }
      }

      @include custom-form-style();
    }
  }
}

/* RS User Forgot Password */
.rs-forgot-password {
  .rs-top-header {
    position: absolute;
    height: 72px;
    left: 0%;
    right: 0%;
    top: 0px;
    background: #006d36;

    .core-logo-header {
      .c-avatar-img {
        @include custom-border-radius(0px);
      }
    }

    a {
      padding: 25px 0px;
      color: #ffffff;
    }
  }

  .card {
    @include custom-border-radius(12px);

    .card-body {
      padding: 1.7rem;

      .card-title {
        font-size: 24px;

        &.errorheader {
          color: #e37575 !important;
        }
      }

      .rs-forgot-password-title {
        color: #2c2d2c;
        margin-top: 24px;
        text-align: justify;
      }

      .rs-login-link {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 0px;

        a {
          color: #2c2d2c;
          text-decoration: underline;

          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }
      }

      @include custom-form-style();
    }
  }
}

/* RS User Reset Password */
.rs-reset-password {
  .rs-top-header {
    position: absolute;
    height: 72px;
    left: 0%;
    right: 0%;
    top: 0px;
    background: #006d36;

    .core-logo-header {
      .c-avatar-img {
        @include custom-border-radius(0px);
      }
    }

    a {
      padding: 25px 0px;
      color: #ffffff;
    }
  }

  .card {
    @include custom-border-radius(12px);

    .card-body {
      padding: 1.7rem;

      .card-title {
        font-size: 24px;

        &.errorheader {
          color: #e37575 !important;
        }
      }

      .rs-reset-password-title,
      .rs-expired-invitation-desc {
        color: #2c2d2c;
        margin-top: 24px;
        text-align: justify;
      }

      .rs-login-link {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 0px;

        a {
          color: #2c2d2c;
          text-decoration: underline;

          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }
      }

      @include custom-form-style();
    }
  }
}

/* RS User Set Password */
.rs-signup,
.rs-terms-and-conditions {
  .rs-top-header {
    position: absolute;
    height: 72px;
    left: 0%;
    right: 0%;
    top: 0px;
    background: #006d36;

    .core-logo-header {
      .c-avatar-img {
        @include custom-border-radius(0px);
      }
    }

    a {
      padding: 25px 0px;
      color: #ffffff;
    }
  }

  .tccontainer {
    margin-top: 120px;
    margin-bottom: 50px;
  }

  .card {
    @include custom-border-radius(12px);

    .card-body {
      padding: 1.7rem;

      .tcphrases {
        p {
          text-align: justify;
          margin: 25px 0px 0px 0px;

          & + p {
            margin-top: 20px;
          }

          &:last-child {
            margin-bottom: 25px;
          }
        }
      }

      .card-title {
        font-size: 24px;

        &.errorheader {
          color: #e37575 !important;
        }
      }

      .rs-signup-title,
      .rs-expired-invitation-desc {
        color: #2c2d2c;
        margin-top: 24px;
        text-align: justify;
      }

      .rs-login-link {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 0px;

        a {
          color: #2c2d2c;
          text-decoration: underline;

          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }
      }

      @include custom-form-style();
    }
  }
}
.registration-header {
  background-color: #00a14b;

  h2,
  p {
    color: #ffffff;
  }
  .registration-header-content {
    margin-top: 20px;
  }
  .MuiButton-contained {
    width: 100%;
    @include custom-button-filled-style();
  }
}
.disable-input-field {
  opacity: 1;
  pointer-events: none;
}

.registration-spacing {
  margin-bottom: 2.5rem !important;
}
.registration-inner_connect {
  margin-left: 10px;
}
#registration-submit-button {
  background: #00a14b;
  color: #ffffff;
  width: 100%;
  &:disabled {
    background: #c4c4c4;
  }
}
.required-field::after {
  content: " *";
  color: red;
}

/* Custom Header Style */
@include custom-page-header();

/* custom design for table scrollinig */
.rs-teebox-groups,
#rs-assets-list,
#rs-alarm-history,
#rs-asset-metrics,
#rs-gateways-list,
#rs-service-history,
#rs-device-and-equipment,
#rs-track-installed-equipment,
#rs-device-data-history,
#rs-edit-service-history,
#rs-site-notification-settings {
  .position-relative {
    max-height: 508px;
    overflow-y: auto;
    scroll-behavior: smooth;

    table {
      th {
        top: 0;
        z-index: 10; // any positive value, layer order is global
        background: #ffffff;
        position: -webkit-sticky !important; // this is for all Safari (Desktop & iOS), not for Chrome
        position: -moz-sticky !important; // this is for all firefox, mozilla
        position: sticky !important;
      }
    }
  }
}

#rs-site-notification-settings {
  td {
    padding: 0 0.75rem;
  }
}

.rs-svg-button {
  width: 32px;
  height: 32px;
  float: right;
  @include action-svg-icon();
}

.rs-breadcrumb {
  margin-bottom: 15px;
  a {
    color: #2c2d2c;
  }
  span {
    font-weight: bold;
  }
}

/* RS Sites List / Site Users List */
#rs-sites-list,
#rs-assets-list,
#rs-alarm-history,
#rs-asset-metrics,
#rs-gateways-list,
#rs-device-data-history,
#rs-service-history,
#rs-site-users-list,
#rs-admin-users-list,
.rs-teebox-groups,
#rs-device-and-equipment,
#rs-track-installed-equipment,
#rs-site-notification-settings,
#rs-add-service-history,
#rs-edit-service-history,
#rs-admin-distributors-list {
  @include custom-alert-box();

  .card {
    @include custom-border-radius(24px);

    .card-body {
      .card-title {
        font-size: 24px;
      }
      .asset-info {
        div:nth-child(even) {
          color: #000000;
          font-weight: 500;
        }
      }
      .divider {
        border-bottom: 1px solid #dfdfdf77;
      }

      .table-footer-text {
        opacity: 0.7;
        color: #2c2d2c;
      }

      @include custom-table-search-item-show();
      @include custom-form-style();

      .MuiFormControl-root {
        &.cancelbutton {
          width: 120px;
        }
        &.invitebutton,
        &.savebutton,
        &.addbutton {
          width: 113px;
        }
      }

      .rs-add-new-button,
      #rs-add-new-site,
      #rs-add-new-gateway,
      #rs-add-new-distributor,
      #rs-invite-new-user {
        @include custom-button-style();
      }

      .position-relative {
        margin: 1rem 0 1rem 0;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        @include custom-border-radius(8px);
      }

      @include custom-data-table-style();
      @include custom-pagination-style();
    }
  }
}

.custom-dialog-box {
  .modal-dialog {
    .modal-content {
      border: none;
      min-height: 140px;
      min-width: 280px;
      background: #ffffff;
      @include custom-border-radius(4px);
      @include custom-box-shadow(
        0px 24px 38px rgba(0, 0, 0, 0.14),
        0px 9px 46px rgba(0, 0, 0, 0.12),
        0px 11px 15px rgba(0, 0, 0, 0.2)
      );

      .modal-body {
        padding: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.6);

        > div {
          display: flex;
          margin-top: 58px;
          justify-content: flex-end;

          .btn {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: #00a14b;

            &:disabled {
              color: #c4c4c4;
            }

            &:focus {
              outline: none;
              @include custom-box-shadow(none);
            }
          }
        }
      }
    }
  }
}

#rs-site-device,
#rs-washer-information,
#rs-teeboxgroup-information,
#rs-ballautomat-information,
#rs-site-settings-information,
#rs-add-ballautomat-information,
#rs-edit-ballautomat-information,
#rs-add-basic-device-information,
#rs-add-teebox-information,
#rs-edit-teebox-information,
#rs-add-equipment-information,
#rs-edit-basic-device-information,
#rs-add-teebox-group-information,
#rs-edit-teebox-group-information,
#rs-ballautomat-configuration {
  @include custom-alert-box();
  .card-title {
    &.child-header {
      @include active-inactive-svg(
        $color: #c4c4c4,
        $active-color: #00a14b,
        $margin-left: 20px
      );
    }
  }

  .pen-edit-button {
    width: 32px;
    height: 32px;
    float: right;
    @include action-svg-icon();
  }

  .settings-info {
    div:nth-child(odd) {
      opacity: 0.7;
      color: #2c2d2c;
    }
  }

  .divider {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #dfdfdf77;
  }

  #add-ballautomat-form,
  #edit-ballautomat-form,
  #add-basic-device-form,
  #add-teebox-form,
  #add-equipment-form,
  #edit-teebox-form,
  #edit-basic-device-form,
  #add-teebox-group-form,
  #edit-teebox-group-form,
  #edit-site-settings-form {
    .helpicons {
      line-height: 5.8;
      svg {
        cursor: pointer;
      }
    }
    @include custom-form-style();
    .MuiFormControl-root {
      &.device-gateway {
        &.attached {
          .MuiFilledInput-adornedEnd {
            background-color: #00a14b;
          }
        }
        .MuiFilledInput-adornedEnd {
          background-color: #c4c4c4;
          input {
            background-color: #f6f6f6;
          }
          .MuiInputAdornment-filled {
            width: 22px;
            height: 58px;
            cursor: pointer;
            .MuiSvgIcon-root {
              color: #ffffff;
            }
          }
        }
      }
      &.cancelbutton {
        width: 120px;
      }
      &.savechangebtn {
        width: fit-content;
      }
      &.invitebutton,
      &.savebutton,
      &.addbutton {
        width: 113px;
      }
    }
  }

  .site-device,
  .washer-info,
  .teeboxgroup-info,
  .site-settings-info,
  .ballautomat-info,
  .ballautomat-config {
    > div {
      > div {
        opacity: 0.7;
        font-size: 14px;
        line-height: 20px;
        color: #2c2d2c;
        padding: 12px 20px 12px 0px;

        &.full-width {
          padding: 12px 0px 12px 0px;

          &:first-child {
            > span {
              color: #00723f;
              font-size: 24px;
              font-weight: bold;
            }
          }

          > span {
            min-width: 150px;
            @include alarm-on-style();
          }
        }

        > span {
          opacity: 1;
          float: right;
          min-width: 200px;
          font-weight: bold;

          &.ballcount {
            font-size: 18px;
            color: #00a14b;
          }

          &.info-property {
            min-width: 340px;
            &.opening-hours {
              min-width: 430px;
              padding-left: 90px;
            }
            > span {
              width: 95px;
              margin-bottom: 16px;
              display: inline-block;
            }
          }
          &.info-property2 {
            min-width: 914px;
          }

          @include alarm-on-style();
          @include active-inactive-svg(
            $color: #a0a0a0,
            $active-color: #006d36,
            $margin-left: 20px
          );
        }

        > div {
          opacity: 1;
          float: right;
          // min-width: 200px;

          &.info-property {
            font-weight: bold;
            // min-width: 340px;
          }
          &.info-property2 {
            // min-width: 914px;
            font-weight: bold;
          }
        }

        .MuiButton-outlined {
          width: 100%;
          @include custom-button-outlined-style();
        }

        &.days-until-service {
          opacity: 1;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          height: 170px;
          margin-top: 22px;

          &.basic-device {
            height: 170px;
            margin-top: 0px;
            padding: 12px 0px 0px 0px;
          }

          div {
            width: 105px;
            height: 105px;
            margin-top: 20px;
            background-repeat: no-repeat;
            background-position: 0px 0px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            line-height: 96px;

            span {
              width: 103px;
              height: 103px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

#rs-site-overview-info {
  .site-overview-info {
    font-size: 14px;
    line-height: 20px;
    color: #2c2d2c;
    margin: 40px 0px 60px 0px;

    .total-ballcount {
      @include site-overview-info($padding-left: 80px, $image: "ballcount.png");
    }

    .total-payment {
      @include site-overview-info(
        $padding-left: 145px,
        $image: "payment-count.png"
      );
    }

    .bays-usage {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "bays-usage.png"
      );
    }

    .total-balls {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "total-balls.png"
      );
    }

    .add-first-device {
      text-align: center;
      font-size: 24px;
      color: #2c2d2c;
      @include site-overview-bgimage(
        $background-position: right 0px,
        $image: "add-first-device.png"
      );

      div {
        width: 55%;
        margin: 0 auto;
        line-height: 32px;
        padding-bottom: 55px;

        & + div {
          & + div {
            padding-bottom: 18px;

            .MuiButton-contained {
              width: 100%;
              @include custom-button-filled-style();
            }
          }
        }
      }
    }
  }
}

#rs-site-overview-info {
  .site-overview-info {
    font-size: 14px;
    line-height: 20px;
    color: #2c2d2c;
    margin: 40px 0px 60px 0px;

    .total-ballcount {
      @include site-overview-info($padding-left: 80px, $image: "ballcount.png");
    }

    .total-payment {
      @include site-overview-info(
        $padding-left: 145px,
        $image: "payment-count.png"
      );
    }

    .bays-usage {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "bays-usage.png"
      );
    }

    .total-balls {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "total-balls.png"
      );
    }

    .add-first-device {
      text-align: center;
      font-size: 24px;
      color: #2c2d2c;
      @include site-overview-bgimage(
        $background-position: right 0px,
        $image: "add-first-device.png"
      );

      div {
        width: 55%;
        margin: 0 auto;
        line-height: 32px;
        padding-bottom: 55px;

        & + div {
          & + div {
            padding-bottom: 18px;

            .MuiButton-contained {
              width: 100%;
              @include custom-button-filled-style();
            }
          }
        }
      }
    }
  }
}

#rs-site-overview-info {
  .site-overview-info {
    font-size: 14px;
    line-height: 20px;
    color: #2c2d2c;
    margin: 40px 0px 60px 0px;

    .total-ballcount {
      @include site-overview-info($padding-left: 80px, $image: "ballcount.png");
    }

    .total-payment {
      @include site-overview-info(
        $padding-left: 145px,
        $image: "payment-count.png"
      );
    }

    .bays-usage {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "bays-usage.png"
      );
    }

    .total-balls {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "total-balls.png"
      );
    }

    .add-first-device {
      text-align: center;
      font-size: 24px;
      color: #2c2d2c;
      @include site-overview-bgimage(
        $background-position: right 0px,
        $image: "add-first-device.png"
      );

      div {
        width: 55%;
        margin: 0 auto;
        line-height: 32px;
        padding-bottom: 55px;

        & + div {
          & + div {
            padding-bottom: 18px;

            .MuiButton-contained {
              width: 100%;
              @include custom-button-filled-style();
            }
          }
        }
      }
    }
  }
}

#rs-site-overview-info {
  .site-overview-info {
    font-size: 14px;
    line-height: 20px;
    color: #2c2d2c;
    margin: 40px 0px 60px 0px;
    @media (max-width: 575px) {
      margin-bottom: 0;
      margin-top: 10px;
    }

    .total-ballcount {
      @include site-overview-info($padding-left: 80px, $image: "ballcount.png");
      @media (max-width: 575px) {
        background-size: 40px auto;
        padding-left: 80px;
        margin-bottom: 10px;
        div {
          margin-bottom: 6px;
        }
      }
    }

    .total-payment {
      @include site-overview-info(
        $padding-left: 145px,
        $image: "payment-count.png"
      );
      @media (max-width: 575px) {
        background-size: auto 40px;
        padding-left: 80px;
        margin-bottom: 10px;
        div {
          margin-bottom: 6px;
        }
      }
    }

    .bays-usage {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "bays-usage.png"
      );
      @media (max-width: 575px) {
        background-size: auto 40px;
        padding-left: 80px;
        margin-bottom: 10px;
        div {
          margin-bottom: 6px;
        }
      }
    }

    .total-balls {
      @include site-overview-info(
        $padding-left: 110px,
        $image: "total-balls.png"
      );
    }

    .add-first-device {
      text-align: center;
      font-size: 24px;
      color: #2c2d2c;
      @include site-overview-bgimage(
        $background-position: right 0px,
        $image: "add-first-device.png"
      );

      div {
        width: 55%;
        margin: 0 auto;
        line-height: 32px;
        padding-bottom: 55px;

        & + div {
          & + div {
            padding-bottom: 18px;

            .MuiButton-contained {
              width: 100%;
              @include custom-button-filled-style();
            }
          }
        }
      }
    }
  }
}

.rs-fullwidth-outlined-button {
  width: 100% !important;
  outline: none !important;
  margin-top: 15px !important;
  margin-bottom: 40px !important;

  &.MuiButton-outlinedSecondary {
    color: #d92929 !important;
    border: 1px solid rgba(245, 1, 86, 0.5) !important;

    &:hover {
      border: 1px solid #d92929 !important;
      background-color: rgba(245, 0, 87, 0.04) !important;
    }
    &:focus {
      outline: none !important;
    }
  }
}

#rs-addnew-button,
#rs-config-device {
  margin-bottom: 20px;
  @include custom-button-style();
}

.ratio-circle {
  circle {
    stroke-width: 12;
    stroke-opacity: 1;
    fill: none;

    &.stroke-green {
      stroke: #00a14b;
    }

    &.stroke-black {
      opacity: 0.6;
      stroke: #000000;
    }
  }
}

@media screen and (max-width: 575px) {
  .c-header-nav {
    flex-wrap: wrap;
    justify-content: center;
    > li {
      margin-bottom: 5px;
    }
  }
}

.error-rect {
  border: 1px solid #006d36;
  width: 498px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.rs-report {
  width: 100%;
}

.rs-report-error {
  text-align: center;
}
