// Variable overrides
@mixin custom-border-radius($args...) {
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: $args;
  /* Firefox 1-3.6 */
  -moz-border-radius: $args;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: $args;
}

@mixin custom-box-shadow($args...) {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -webkit-box-shadow: $args;
  /* Firefox 3.5 - 3.6 */
  -moz-box-shadow: $args;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS */
  box-shadow: $args;
}

@mixin custom-box-sizing($args...) {
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -webkit-box-sizing: $args;
  /* Firefox 3.5 - 3.6 */
  -moz-box-sizing: $args;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS */
  box-sizing: $args;
}

@mixin custom-page-header($args...) {
  h2.custom-page-header {
    font-size: 24px;
    color: #000000;
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

@mixin custom-button-outlined-style($args...) {
  height: 40px;
  outline: none;
  color: #00A14B;
  text-transform: uppercase;
  border: 1px solid #00A14B;
  @include custom-border-radius(3px);
}

@mixin custom-button-filled-style($args...) {
  height: 40px;
  color: #ffffff;
  outline: none;
  text-transform: uppercase;
  background-color: #00A14B;
  &:hover {
    background-color:#33B570;
}
  @include custom-border-radius(3px);
}

@mixin custom-button-outlined($args...) {
  .MuiButton-outlined {
    @include custom-button-outlined-style();
  }
}

@mixin custom-button-filled($args...) {
  .MuiButton-contained {
    @include custom-button-filled-style();

    &.Mui-disabled {
      color: #ffffff;
      background-color: #c4c4c4;
    }

    &.not-full-width {
      width: 113px;
    }
  }
}

@mixin custom-button-style($args...) {
  float: right;
  @include custom-button-filled-style();
}

@mixin custom-form-style($args...) {
  .MuiFormControl-root {
    width: 100%;
    margin: 20px 0px;

    &.divider {
      padding-bottom: 35px;
      border-bottom: 1px solid #dfdfdf77;
    }

    &.checkbox {
      margin: 0px;

      a {
        opacity: 1;
        color: #00A14B;
        text-decoration: underline;
      }
    }

    .MuiFormLabel-root {
      line-height: 0;
      margin-top: 2px;

      &.Mui-focused {
        color: #38b472;
      }

      &.Mui-error {
        color: #d92929;
      }

      &.Mui-filled {
        color: #2c2d2c;
      }
    }

    .MuiFilledInput-root {
      background-color: #f6f6f6;

      .MuiInputAdornment-positionStart {
        margin-right: 0px;

        .MuiPhoneNumber-flagButton {
          outline: none;
          margin-left: -2px;

          .MuiButton-label {
            .flag {
              transform: scale(1.5);
            }
          }
        }
      }
    }

    .MuiInputBase-root.Mui-disabled {
      background-color: #dfdfdf;
    }

    .MuiFilledInput-underline {
      &:before {
        border-bottom: 2px solid transparent;
      }

      &:after {
        border-bottom: 2px solid #38b472;
      }

      &.Mui-error {
        &:after {
          border-bottom-color: #d92929;
        }
      }

      &.Mui-filled {
        &:after {
          border-bottom-color: #2c2d2c;
        }
      }

      .MuiFilledInput-input {
        filter: none;
        padding: 20px 12px 5px;
        @include remove-autofill-style();
      }
    }

    @include custom-button-outlined();
    @include custom-button-filled();
  }
}

/** use inside input */
@mixin remove-autofill-style($args...) {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      -webkit-background-clip: text;
    }
  }
}

@mixin custom-pagination-style($args...) {
  .custom-pagination {
    margin-top: -22px;
    display: block !important;

    .pagination {
      .page-item {
        .page-link {
          width: 24px;
          height: 24px;
          color: #000000;
          background: #ffffff;
          opacity: 0.7;
          margin-left: 10px;
          padding: 0px;
          border: 1px solid #a4a3a7;
          box-sizing: border-box;
          @include custom-border-radius(8px);

          .MuiSvgIcon-root {
            margin-left: -1px;
          }
        }

        &.disabled {
          .page-link {
            color: #969696;
          }
        }

        &.active {
          .page-link {
            color: #000000;
            text-align: center;
            padding-top: 4px;
            border: 0px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@mixin custom-table-search-item-show($args...) {
  div.col {
    > div {
      .row.my-2.mx-0 {
        flex-direction: row-reverse;

        .c-datatable-filter {
          justify-content: flex-end;

          label.mfe-2 {
            display: none;
          }

          input.form-control {
            width: 200px;
            outline: none;
            border-width: 0px 0px 1px;
            border-color: #dfdfdf;
            @include custom-border-radius(0px);
            background-image: url("/assets/images/search.png");
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: 18px;

            &:focus {
              @include custom-box-shadow(none);
              border-bottom: 2px solid #38b472;
            }
          }
        }

        .c-datatable-items-per-page {
          float: left;

          select {
            margin: 0px;
            height: 23px;
            padding: 0px 20px 0px 15px;
            @include custom-border-radius(25px);
          }
        }
      }
    }
  }
}

@mixin action-svg-icon($args...) {
  padding: 3px;
  cursor: pointer;
  background: #ffffff;
  @include custom-border-radius(4px);
  @include custom-box-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));

  &:hover {
    @include action-svg-icon-hover();
  }
}

@mixin action-svg-icon-hover($args...) {
  @include custom-box-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

@mixin custom-data-table-style($args...) {
  .table {
    color: #2c2d2c;
    margin-bottom: 0px;

    &.table-hover {
      tbody {
        tr {
          &:hover {
            color: #2c2d2c;
            background: rgba(71, 250, 132, 0.1);

            td {
              .MuiSvgIcon-root {
                &.action-icon {
                 //@include action-svg-icon-hover();
                  &.active {
                    background: #d5d5d5;
                  }
                }
              }
            }
          }
        }
      }
    }

    tr {
      &.user-edit-form,
      &.service-edit-form,
      &.distributor-edit-form {
        background: #ffffff !important;
        box-shadow: 0px 4px 4px inset rgb(0 0 0 / 25%);
      }

      td {
        vertical-align: middle;
        border: 1px solid #dfdfdf;
        &.equipment-archived {
          opacity: 0.4;
          color: #2c2d2c;
        }
        @include alarm-on-style();

        .MuiFormControlLabel-labelPlacementStart {
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
        }

        .MuiSvgIcon-root {
          &.action-icon {
            @include action-svg-icon();
            &.active {
              background: #d5d5d5;
            }
          }

          &.rs-copy-icon {
            float: right;
            margin-right: 20px;
          }
        }

        a {
          color: #3c4b64;
        }

        .content-details {
          background: #ffffff !important;
          box-shadow: 0px 4px 4px inset rgb(0 0 0 / 25%);

          div:nth-child(odd) {
            color: #8b8b8b;
            text-align: justify;
          }
          div:nth-child(even) {
            color: #2c2d2c;
            text-align: justify;
          }
        }
      }
    }
  }
}

@mixin custom-alert-box($args...) {
  .custom-alert {
    span {
      float: right;
      cursor: pointer;
      color: #86ffaf;
      text-transform: uppercase;
    }
  }
}

@mixin site-overview-bgimage(
  $image,
  $height: 78px,
  $padding-left: 0px,
  $background-repeat: no-repeat,
  $background-position: 0px 0px
) {
  min-height: $height;
  padding-left: $padding-left;
  background-repeat: $background-repeat;
  background-position: $background-position;
  background-image: url("/assets/images/#{$image}");
}

@mixin site-overview-info(
  $image,
  $height: 78px,
  $padding-left: 0px,
  $background-repeat: no-repeat,
  $background-position: 0px 0px
) {
  @include site-overview-bgimage(
    $image,
    $height,
    $padding-left,
    $background-repeat,
    $background-position
  );

  div {
    opacity: 0.7;
    margin-bottom: 20px;

    & + div {
      opacity: 1;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      color: #00A14B;
    }
  }
}

@mixin active-inactive-svg($color, $active-color, $margin-left) {
  .MuiSvgIcon-root {
    color: $color;
    margin-left: $margin-left;

    &.active {
      color: $active-color;
    }
  }
}

@mixin alarm-on-style($args...) {
  &.alarm-on {
    color: #be0000;
  }
}

@mixin youtube-video-instruction($args...) {
  .modal-success {
    .modal-content {
      border-width: 0px;
      @include custom-border-radius(16px);
    }
  }
  .close-player {
    svg {
      float: right;
      cursor: pointer;
    }
  }
  .video-link {
    z-index: 1;
    padding: 33.5% 44.8%;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/play.png");
  }
  .support-info {
    div {
      font-size: 18px;
      color: #2c2d2c;
      margin-top: 20px;

      & + div {
        opacity: 0.5;
        margin-top: 10px;
        color: #2c2d2c;
      }
    }
  }
  .youtube-link {
    float: right;
    a {
      float: right;
      margin-top: 20px;
      color: #00A14B;
      font-size: 18px;
      text-decoration: underline;
      &:hover {
        color: #00803c;
      }
    }
  }
  .support-desc {
    overflow-y: auto;
    max-height: 120px;
    margin-top: 16px;
    margin-bottom: 18px;
    padding: 0px 2px;
  }
  .contact-link {
    color: #000000;
    padding-top: 25px;
    padding-bottom: 25px;
    a {
      color: #00A14B;
    }
  }
}
